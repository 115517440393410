









import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    mode: {
      type: String,
      default: 'rgba',
    },
  },
  setup(props, context) {
    const color = ref(props.value);
    const update = (value: { rgba: { r: number; g: number; b: number; a: number }; hex: string }) => {
      if (props.mode === 'rgba') {
        context.emit('input', `${value.rgba.r},${value.rgba.g},${value.rgba.b}`);
      } else if (props.mode === 'hexa') {
        context.emit('input', value.hex);
      }
    };
    watch(
      () => props.value,
      () => {
        const getHex = (str: string) =>
          (
            '0' +
            Number(str)
              .toString(16)
              .toUpperCase()
          ).slice(-2);
        if (props.value && props.mode === 'rgba') {
          const [r, g, b] = props.value.split(',');
          color.value = `#${getHex(r)}${getHex(g)}${getHex(b)}`;
        } else if (props.value && props.mode === 'hexa') {
          color.value = props.value.slice(0, 7);
        }
      }
    );
    return { color, update };
  },
});
