





























































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import { useFavorite, ImageSetting } from '@/composition/favorites';
import { useConfirm } from '@/composition/confirm';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcColor from '@/components/FcColor.vue';
import FcDate from '@/components/FcDate.vue';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Favorite',
  components: {
    FcImage,
    FcStaticImage,
    FcColor,
    FcDate,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    favoriteItemId: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('favorites'));
    const { confirmDialog } = useConfirm();

    const { favorite } = useFavorite(props.favoriteItemId);
    const notification = useNotification();
    const isSaving = ref(false);
    const headers = [
      { text: '画像', value: 'image' },
      { text: '表示日時', value: 'displayStartDate' },
      { text: '表示終了日時', value: 'displayEndDate' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const openDialog = () => {
      favorite.isActiveDialog = true;
    };

    const closeDialog = () => {
      favorite.isActiveDialog = false;
      favorite.resetImageSetting();
    };

    const saveImageSetting = () => {
      try {
        favorite.saveImageSetting();
      } catch (error) {
        notification.error(error);
      }
    };

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (props.favoriteItemId) {
          await favorite.saveFavorite();
          notification.notify('変更しました。');
        } else {
          await favorite.createFavorite();
          notification.notify('作成しました。');
          context.root.$router.push('/favorites');
        }
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    const edit = (item: ImageSetting) => {
      favorite.isActiveDialog = true;
      favorite.editImageSettingItem = item;
      favorite.imageSetting.image = item.image;
      favorite.imageSetting.displayStartDate = new DisplayDate(item.displayStartDate.value);
      favorite.imageSetting.displayEndDate = item.displayEndDate || new DisplayDate();
    };

    const remove = async (item: ImageSetting) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      const index = favorite.item.imageSettings.indexOf(item);
      // 最後の要素を削除したら、追加ボタンを押せるようにする
      if (favorite.item.imageSettings.length - 1 === index) {
        favorite.isImageSettingLastEndDate = true;
      }
      favorite.item.imageSettings.splice(index, 1);
    };

    return {
      pageTitle: '推しメン設定',
      myRoleSettings,
      favorite,
      headers,
      isSaving,
      openDialog,
      closeDialog,
      saveImageSetting,
      save,
      edit,
      remove,
    };
  },
});
